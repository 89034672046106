<template>
    <v-row v-if="section == 1" class="mt-2">
        <v-col cols="12">
            <v-btn
                text
                color="text lighten-1"
                link
                :to="{ name: 'MyProjects' }"
            >
                <v-icon class="mr-2">
                    fa-solid fa-arrow-left
                </v-icon>
                {{ $t(`MyProjects.returnBack`) }}
            </v-btn>
        </v-col>
        <v-col cols="12">
            <project-info-card
                v-if="project.id"
                :project="project"
                type="project"
                card-width="100%"
                :card-heigth="cardHeight"
                :media-height="mediaHeight"
                media-width="540"
            ></project-info-card>

            <div v-if="hasDescription" class="mt-10">
                <description-alert
                    color="secondary darken-2"
                    icon="fa-solid fa-seedling"
                >
                    <template #description>
                        {{ project.description }}
                    </template>
                </description-alert>
            </div>
        </v-col>
        <v-col cols="12" class="d-md-flex justify-md-space-between">
            <requirements
                v-if="hasIngredients"
                :title="$t(`project.requirements`)"
                :items="project.ingredients"
            ></requirements>
            <view-steps-btn
                v-if="hasSteps"
                @view-steps="viewSteps()"
            ></view-steps-btn>
        </v-col>
    </v-row>
    <v-row v-else class="mt-2">
        <v-col cols="12">
            <v-btn text color="text lighten-1" link @click="returnBack()">
                <v-icon class="mr-2">
                    fa-solid fa-arrow-left
                </v-icon>
                {{ $t(`MyProjects.myProjectInfo.back`) }}
            </v-btn>
        </v-col>
        <v-col v-if="project.steps.length > 0" cols="12">
            <view-stepper :project-steps="project.steps"></view-stepper>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getProject } from "@/api/projectsApi.js";
import { CONFIG } from "@/common/config.js";
import { mapState } from "vuex";

import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import DescriptionAlert from "@/components/General/DescriptionAlert.vue";
import Requirements from "@/components/General/Requirements.vue";
import ViewStepsBtn from "@/components/General/ViewStepsBtn.vue";
import ViewStepper from "@/components/General/ViewStepper.vue";
import picture from "@/assets/icons/picture_thumb.png";
export default {
    name: "MyProjectInfo",

    components: {
        ProjectInfoCard,
        DescriptionAlert,
        Requirements,
        ViewStepsBtn,
        ViewStepper,
    },

    data() {
        return {
            section: 1,
            project: {},
            cardHeight: CONFIG.card_heigth,
            mediaHeight: CONFIG.media_height,
        };
    },

    computed: {
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),
        hasSteps() {
            return this.project?.steps?.length > 0 ? true : false;
        },
        hasIngredients() {
            return this.project.ingredients ? true : false;
        },
        hasDescription() {
            return this.project.description ? true : false;
        },
    },

    methods: {
        async handleProject() {
            const paylaod = {
                id: this.$route.params.id,
            };

            let url = ``

            const { response, error } = await withAsync(getProject, paylaod);

            if (error) {
                return;
            }
            this.project = response.data.data;
        },
        viewSteps() {
            this.section = 2;
        },
        returnBack() {
            this.section = 1;
        },
        getType() {
            switch (this.user_role) {
                case "user":
                    return "projects";
                case "admin":
                    return "projects";
                case "chef":
                    return "recipes";
                default:
                    return "project";
            }
        },
    },

    created() {
        this.handleProject();
    },
};
</script>

<style lang="scss" scoped></style>
