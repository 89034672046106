<template>
    <v-alert text :color="color" :icon="icon" border="left">
        <slot name="description"></slot>
    </v-alert>
</template>

<script>
export default {
    name: "DescriptionAlert",

    props: {
        color: {
            required: true,
            type: String,
        },
        icon: {
            required: true,
            type: String,
        },
    },
};
</script>
